/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    &:hover {
      border-color: var(--#{$prefix}border-color);
    }
  }
  .css-t3ipsp-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    box-shadow: none;
    &:hover {
      border-color: var(--#{$prefix}border-color) !important;
    }
  }
  .css-1nmdiq5-menu {
    background-color: var(--#{$prefix}gray-200);
    .css-10wo9uf-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-200);
      &:active {
        background-color: var(--#{$prefix}gray-200);
      }
    }
    .css-d7l1ni-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-100);
    }
  }
  .css-1p3m7a8-multiValue {
    background-color: var(--#{$variable-prefix}light);
  }
  .css-wsp0cs-MultiValueGeneric {
    color: $heading-color;
  }
  .css-12jo7m5 {
    color: $heading-color;
  }
  .css-6j8wv5-Input,
  .css-qc6sy-singleValue {
    color: $heading-color;
  }

  .css-1dimb5e-singleValue {
    color: var(--vz-body-color);
  }
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
  top: 24px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 22px;
  line-height: 2;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 35s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

// Data Table

.kVrXuC,
.dwPlXY,
.gKbhqU,
.iSAVrt {
  background-color: $table-bg !important;
  color: $table-color !important;
}

.fyrdjl {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
}

.fyrdjl:disabled {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
}

.iSAVrt {
  background-color: var(--#{$prefix}card-bg-custom) !important;
  border-bottom-color: $table-border-color !important;
}

.dwPlXY:not(:last-of-type) {
  border-bottom-color: $table-border-color !important;
}

.Toastify .Toastify__toast {
  min-height: 60px !important;
}

.container .col {
  padding: 10px;
  background-color: #dbd7f0;
  font-weight: bold;
}

.tasks-wrapper {
  max-height: calc(100vh - 200px);
}

.required {
  color: #f06548;
  padding-left: 1px;
  font-weight: bold;
}

.rbt-menu {
  width: 100%;
}

.simplebar-height-auto-observer-wrapper {
  min-height: 100vh;
}

.form-label {
  margin-top: 0.5rem;
  margin-bottom: 0px;
}

.tasks-box {
  margin-bottom: 1rem;
}

.form-control:read-only{
  background-color: #F4F4F4;
}

.flatpickr-input:read-only{
  background-color: white;
}

.form-select:disabled{
  background-color: #F4F4F4;
}

.table-form {
  width: 100%;
  margin-top: 10px;
  background-color: white;
}

.table-form-readonly {
  background-color: #F4F4F4;
}

.table-form td {
  border: 2px solid #D4D4D4;
  padding: 5px;
  color: black;
  text-align: left;
}

.table-form .header {
  background-color: #F2F2F2;
  font-weight: bold;
}

.table-form .header-red {
  background-color: red;
  font-weight: bold;
  color: white !important;
}

.table-form .header-red td {
  color: white !important;
}

.table-form .index-col {
  width: 50px;
  text-align: center;
  font-weight: bold;
}

.table-form .check-col {
  width: 100px;
  text-align: center;
}

.table-form .small-col {
  width: 50px;
  text-align: center;
  font-size: 70%;
}

.table-form-alt {
  width: 100%;
  margin-top: 10px;
}

.table-form-alt td {
  border: none !important;
}

.table-form-inner {
  width: 100%;
}

.table-form-inner td {
  border: none !important;
}

.table-form-inner td.border-right {
  border-right: 2px solid #D4D4D4 !important;
}

.table-form-inner td.border-left {
  border-left: 2px solid #D4D4D4 !important;
}

.checkbox-is-invalid {
  outline: 1px solid red;
}

.bg-pd-review {
  background-color: #808000;
}

.border-pd-review {
  border-color: #808000;
}

.bg-pd-support {
  background-color: #B0B279;
}

.border-pd-support {
  border-color: #B0B279;
}

.bg-sampling {
  background-color: #5D5D00;
}

.border-sampling {
  border-color: #5D5D00;
}

.bg-final-decision {
  background-color: #800080;
}

.border-final-decision {
  border-color: #800080;
}

.bg-scheduled {
  background-color: #FFA500;
}

.border-scheduled {
  border-color: #FFA500;
}

.bg-waiting-corrective-action {
  background-color: #DC143C;
}

.border-waiting-corrective-action {
  border-color: #DC143C;
}

.bg-phase-1 {
  background-color: #FFD700;
}

.border-phase-1 {
  border-color: #FFD700;
}

.bg-inspection {
  background-color: #B4C424;
}

.border-inspection {
  border-color: #B4C424;
}

.bg-phase-2 {
  background-color: #DAA520;
}

.border-phase-2 {
  border-color: #DAA520;
}

.bg-complete {
  background-color: #8BCB45;
}

.border-complete {
  border-color: #8BCB45;
}

.richtext-wrapper {

}

.richtext-toolbar {
  width: 99%;
}

.richtext-editor {
  min-height: 400px;
  border: 1px solid var(--vz-input-border);
  border-radius: 0.25rem;
  display: block;
  width: 99%;
  padding: 0.5rem 0.9rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--vz-body-color);
  background-color: var(--vz-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--vz-input-border);
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.initial-color-1 {
  background-color: $blue !important;
}

.initial-color-2 {
  background-color: $indigo !important;
}

.initial-color-3 {
  background-color: $pink !important;
}

.initial-color-4 {
  background-color: var($red) !important;
}

.initial-color-5 {
  background-color: var($orange) !important;
}

.initial-color-6 {
  background-color: var($yellow) !important;
}

.initial-color-7 {
  background-color: var($green) !important;
}

.initial-color-8 {
  background-color: var($teal) !important;
}

.initial-color-9 {
  background-color: var($cyan) !important;
}

.initial-color-10 {
  background-color: var($blue-100) !important;
}

.initial-color-11 {
  background-color: var($blue-200) !important;
}

.initial-color-12 {
  background-color: var($blue-300) !important;
}

.initial-color-13 {
  background-color: var($indigo-100) !important;
}

.initial-color-14 {
  background-color: var($indigo-200) !important;
}

.initial-color-15 {
  background-color: var($indigo-300) !important;
}

.initial-color-16 {
  background-color: var($pink-100) !important;
}

.initial-color-17 {
  background-color: var($pink-200) !important;
}

.initial-color-18 {
  background-color: var($pink-300) !important;
}

.rbt-input-multi.form-control {
  background-color: white !important;
}

.rbt-input-multi.disabled {
  background-color: #F4F4F4 !important;
}

.rbt-token {
  color: black !important;
}

.strike-text {
  text-decoration: line-through;
}